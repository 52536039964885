export default function About() {
    return (
      <main style={{ padding: "1rem 0" }}>
         <div className='container col-lg-8 col-md-10 mx-auto' style={{maxWidth:'90%'}}>
          <br /> <br /> <br /><br /> <br /> <br />
          <h2>Blog sobre geomàtica:</h2>
          <a target="_blank" href="https://geomapsblog.com/"><h4>https://geomapsblog.com/</h4></a> 
          <br /><br />
          <h2>Versió anterior de GeoAtles:</h2>
          <a target="_blank" href="https://github.com/JosepAnSabate/Geoapp_node_mongo_leaflet_auth"><h4>https://github.com/JosepAnSabate/Geoapp_node_mongo_leaflet_auth</h4></a> 
        
        </div>
      </main>
    );
  }