import React from 'react';

function Error (){
    return (
    <>
      <h3>Error page</h3>
       
    </>
    )
    
}
  
export default Error;